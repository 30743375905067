import ConfirmPurchaseTaxYear from '../../components/Revisor/ConfirmPurchaseTaxYear'
import React from 'react'
import Pagewrapper from '../../components/PageWrapper'

export default function () {
    return (
        <Pagewrapper>
            <ConfirmPurchaseTaxYear />
        </Pagewrapper>
    )
}
